/** @format */

import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import UserOtp from "../views/UserOtp.vue";
import DashboardView from "../views/DashboardView.vue";
import OutletSale from "../views/OutletSale.vue";
import DatewiseSale from "../views/DatewiseSale.vue";
import SaleReport from "../views/SaleReport.vue";
import SaletypeReport from "../views/SaletypeReport.vue";
import PoswiseSale from "../views/PoswiseSale.vue";
import CusineSale from "../views/CusineSale.vue";
import CancelbillReport from "../views/CancelbillReport.vue";
import CancelkotReport from "../views/CancelkotReport.vue";
import ContactReport from "../views/ContactReport.vue";
import FeedbackReport from "../views/FeedbackReport.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
    beforeRouteEnter(to, from, next) {
      console.log("to:" + to);
      console.log("from:" + from);
      console.log("next:" + next);
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: HomeView,
    children: [
      // Masters
      {
        path: "/dashboard",
        component: DashboardView,
        beforeRouteEnter(to, from, next) {
          console.log("to:" + to);
          console.log("from:" + from);
          console.log("next:" + next);
        },
      },
      {
        path: "/otplist",
        component: UserOtp,
      },
      {
        path: "/outletsale",
        component: OutletSale,
      },
      {
        path: "/datewise",
        component: DatewiseSale,
      },
      {
        path: "/salerep",
        component: SaleReport,
      },
      {
        path: "/saletype",
        component: SaletypeReport,
      },
      {
        path: "/poswise",
        component: PoswiseSale,
      },
      {
        path: "/cusinewise",
        component: CusineSale,
      },
      {
        path: "/canbillrep",
        component: CancelbillReport,
      },
      {
        path: "/cankotrep",
        component: CancelkotReport,
      },
      {
        path: "/contactrep",
        component: ContactReport,
      },
      {
        path: "/feedbackrep",
        component: FeedbackReport,
      },
    ],
  },
];

export default routes;
