<!-- @format -->

<template>
  <inv-detail
    :tc="invtc"
    :ser="invser"
    :docno="invno"
    :docdt="invdt"
    :outletId="outletId"
    :outletName="outletName"
    :cancelyn="false"
    ref="invdet"
  >
  </inv-detail>
  <div class="form-div">
    <div class="title-bar">
      <div class="title-text">{{ caption }}</div>
      <div class="close-btn">
        <router-link to="/dashboard">
          <span class="fas fa-times-circle"></span
        ></router-link>
      </div>
    </div>
    <div class="content-area">
      <div class="filter-area">
        <div class="dt-filter">
          <div class="form-group">
            <label for="mdate1">From Date:</label>
            <input type="date" class="form-control dt" v-model="mdate1" />
          </div>

          <div class="form-group">
            <label for="mdate2">To Date:</label>
            <input type="date" class="form-control dt" v-model="mdate2" />
          </div>
          <!-- Filter dt-Area Ends -->
        </div>

        <div class="form-group select" v-if="UserFlag == 'A'">
          <label for="mdate2">Outlet Name:</label>
          <select class="form-select" v-model="outletId">
            <option value="ALL">--Select Outlet--</option>
            <option
              v-for="opt in outlets"
              :value="opt.outlet_id"
              :key="opt.outlet_id"
            >
              {{ opt.outlet_name }}
            </option>
          </select>
        </div>

        <div class="form-group filter-btn">
          <button type="button" class="btn btn-primary" @click="filterRec">
            <span class="fas fa-filter"></span>
            Filter
          </button>
          &nbsp;
          <button type="button" class="btn btn-secondary" @click="clearFilter">
            <span class="fas fa-filter">
              <span class="fas fa-slash fa-inverse"></span>
            </span>
            Clear Filter
          </button>
          &nbsp;
          <button
            type="button"
            class="btn btn-warning"
            @click="exportPdf"
            ref="exportbtn"
          >
            <span class="fas fa-file-pdf"></span>
            Export
          </button>
          &nbsp;
          <button
            type="button"
            class="btn btn-warning"
            @click="exportExcel"
            ref="exportbtn"
          >
            <span class="fas fa-file-excel"></span>
            Export to Excel
          </button>

          <span class="exporting" v-html="ExportLink"> </span>
          <br />
          <div class="foc-div">
            <label for="foconly">FOC Only</label>&nbsp;
            <input
              type="checkbox"
              name="foconly"
              id="foconly"
              v-model="foconly"
            />
          </div>
        </div>

        <!-- <input
          type="text"
          class="form-control searchbox"
          placeholder="Search"
          v-model="search"
          @keyup="filterRec"
          ref="search"
          autofocus
        /> -->
      </div>

      <vue3-simple-html2pdf
        ref="vue3SimpleHtml2pdf"
        :options="pdfOptions"
        :filename="exportFilename"
      >
        <div class="grid-area" id="gridarea">
          <div class="pdf-title" v-if="exporting">
            {{ caption }} From:{{ repdt1 }} To:
            {{ repdt2 }}
          </div>
          <card-loader v-if="loading"></card-loader>
          <table border="1" v-else>
            <thead>
              <tr>
                <th>#</th>
                <th>Outlet Name</th>
                <th class="tocenter">Inv.No.</th>
                <th class="tocenter">Date</th>
                <th class="toright">Gross Amount</th>
                <th class="toright">Discount</th>
                <th class="toright">Taxable</th>
                <th class="toright">GST</th>
                <th class="toright">Bill Amount</th>
                <th class="toright">Cash</th>
                <th class="toright">C.C.</th>
                <th class="toright">Zomato /Swiggy</th>
                <th class="toright">UPI</th>
                <th class="tocenter">Customer</th>
                <th class="tocenter">Phone</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in mstList"
                :key="row.uid"
                @click="showDetail(row)"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ row.outlet_name }}</td>
                <td class="toleft">
                  {{ row.ser.trim() + "/" + row.docno.trim() }}
                </td>
                <td class="tocenter">{{ row.dt }}</td>
                <td class="toright">{{ row.amount1 }}</td>
                <td class="toright">{{ row.dis }}</td>
                <td class="toright">{{ row.taxable }}</td>
                <td class="toright">{{ row.gst }}</td>
                <td class="toright">{{ row.billamt }}</td>
                <td class="toright">{{ row.pay1 }}</td>
                <td class="toright">{{ row.pay2 }}</td>
                <td class="toright">{{ row.pay3 }}</td>
                <td class="toright">{{ row.pay4 }}</td>
                <td class="toleft">{{ row.gln }}</td>
                <td class="toleft">{{ row.phone }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4" class="toright">Total</td>
                <td class="toright">{{ getTotal(mstList, "amount1") }}</td>
                <td class="toright">{{ getTotal(mstList, "dis") }}</td>
                <td class="toright">{{ getTotal(mstList, "taxable") }}</td>
                <td class="toright">{{ getTotal(mstList, "gst") }}</td>
                <td class="toright">{{ getTotal(mstList, "billamt") }}</td>
                <td class="toright">{{ getTotal(mstList, "pay1") }}</td>
                <td class="toright">{{ getTotal(mstList, "pay2") }}</td>
                <td class="toright">{{ getTotal(mstList, "pay3") }}</td>
                <td class="toright">{{ getTotal(mstList, "pay4") }}</td>
                <td colspan="2" class="toright">&nbsp;</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </vue3-simple-html2pdf>
    </div>
  </div>
</template>

<script>
import CardLoader from "@/components/CardLoader.vue";
import InvDetail from "./InvDetail.vue";
import MyFun from "../js/MyFun.js";

import axios from "axios";
export default {
  components: {
    CardLoader,
    InvDetail,
  },
  data() {
    return {
      formId: "022",
      optn: "Add",
      permission: [],
      search: "",
      mstList: [],
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      UserFlag: this.$store.state.userFlag,
      errMsg: "",
      errType: "",
      loading: false,
      mdate1: new Date().toJSON().slice(0, 10).replace("/", "-"),
      mdate2: new Date().toJSON().slice(0, 10).replace("/", "-"),
      repdt1: "",
      repdt2: "",
      showBox: false,
      strackno: 0,
      outlets: [],
      outletName: "",
      outletId: this.$store.state.posId,
      invtc: "",
      invser: "",
      invno: "",
      invdt: "",
      caption: "Sale Detail",
      exportFilename: "Sale_Detail.pdf",
      pdfOptions: {
        margin: 10,
        image: {
          type: "jpeg",
          quality: 1,
        },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "l",
        },
      },
      exporting: false,
      pageNumber: 0,
      ExportLink: "",
      foconly: false,
    };
  },
  methods: {
    async filterRec() {
      this.loading = true;
      this.ExportLink = "";

      await axios
        .post("reports.php", {
          optn: "salerep",
          mdate1: this.mdate1,
          mdate2: this.mdate2,
          msearch: this.search,
          uid: this.userId,
          uname: this.userName,
          outletid: this.outletId,
          foconly: this.foconly,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.mstList = resp.List;
          } else {
            console.log("Error:" + this.ErrorMsg);
          }
        });
      this.loading = false;
    },
    async getOutlets() {
      await axios
        .post("reports.php", {
          optn: "outletlist",
          uid: this.userId,
          uname: this.userName,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.outlets = resp.List;
          } else {
            console.log("Error:" + this.ErrorMsg);
          }
        });
    },
    async showDetail(xrow) {
      this.invtc = xrow.tc;
      this.invser = xrow.ser;
      this.invno = xrow.docno;
      this.invdt = xrow.docdt;
      this.outletName = xrow.outlet_name;
      this.outletId = xrow.outlet_id;
      await this.$refs.invdet.toggleForm();
      await this.$refs.invdet.getDetail();
    },
    getTotal(arr, field) {
      var i = 0;
      var total = 0;
      for (i = 0; i < arr.length; i++) {
        var myval = arr[i];
        total += parseFloat(myval[field]);
      }
      return total.toFixed(2);
    },
    async exportPdf() {
      var grid = document.getElementById("gridarea");
      grid.classList.add("sprintgrid-area");
      this.exporting = true;
      await this.$refs.vue3SimpleHtml2pdf.download();
      this.exporting = false;
      grid.classList.remove("sprintgrid-area");
    },
    async exportExcel() {
      this.ExportLink = "Exporting....";
      await axios
        .post("reportsxls.php", {
          optn: "salerep",
          mdate1: this.mdate1,
          mdate2: this.mdate2,
          msearch: this.search,
          uid: this.userId,
          uname: this.userName,
          outletid: this.outletId,
          foconly: this.foconly,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.ExportLink = resp.Link;
          } else {
            console.log(response);
            console.log("Error:" + this.ErrorMsg);
          }
        });
    },
  },
  async mounted() {
    this.filterRec();
    this.getOutlets();
    this.repdt1 = MyFun.tobrit(this.mdate1);
    this.repdt2 = MyFun.tobrit(this.mdate2);
  },
  watch: {
    mdate1: function (val) {
      this.repdt1 = MyFun.tobrit(val);
    },
    mdate2: function (val) {
      this.repdt2 = MyFun.tobrit(val);
    },
  },
};
</script>
<style>
@import "../css/main.css";
.invbox {
  display: none;
}
.foc-div {
  position: relative;
  display: block;
  margin-top: 10px;
}
</style>
