<!-- @format -->

<template>
  <div class="login-div">
    <card-loader
      v-show="isChecking"
      :LoaderText="'Authenticating..'"
    ></card-loader>
    <div class="inner-div" v-show="!isChecking">
      <img src="../assets/complogo.jpg" class="logo" alt="Logo" />
      <label class="tocenter"><u>Login</u></label>
      <span class="errmsg" :class="errclass" v-if="err">{{ errmsg }}</span>
      <label for="uname">User Name:</label>
      <input
        type="text"
        name="uname"
        class="form-control"
        v-model="uname"
        maxlength="20"
        autocomplete="off"
        autofocus
      />
      <label for="upass">Password:</label>
      <input
        type="password"
        name="upass"
        class="form-control"
        v-model="upass"
        maxlength="20"
        autocomplete="off"
      />
      <button class="btn btn-success" type="button" @click="login()">
        Login
      </button>
    </div>
    <div class="devcontact">
      <a href="mailto:ssoftwares323@rediffmail.com">KVR Technologies</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CardLoader from "./CardLoader.vue";
export default {
  name: "LoginForm",
  emits: ["login-btn"],
  components: { CardLoader },
  data() {
    return {
      uname: "",
      upass: "",
      err: false,
      errmsg: "",
      errclass: "",
      isChecking: false,
    };
  },
  methods: {
    async login() {
      this.uname = this.uname.toUpperCase();
      this.upass = this.upass.toUpperCase();
      if (this.uname != "" && this.upass != "") {
        this.isChecking = true;
        this.err = false;
        this.errclass = "warnmsg";
        this.errmsg = "Authenticating...";
        var loginsts = {
          isAuth: false,
          Uname: this.uname,
          Uid: "",
          StoreFlg: "",
          UserFlag: "",
          PosId: "",
        };

        var response;
        await axios
          .post("users.php", {
            optn: "checkuser",
            uname: this.uname,
            upass: this.upass,
          })
          .then(function (resp) {
            response = resp.data;
          });
        this.isChecking = false;
        if (response.Errorsts === false) {
          // console.log(response.List);
          if (response.List.length > 0) {
            this.errclass = "donemsg";
            this.errmsg = "Welcome!" + this.uname;
            loginsts.isAuth = true;
            loginsts.Uid = response.List[0].uid;
            loginsts.Uname = response.List[0].uid;
            loginsts.UserFlag = response.List[0].catg;
            loginsts.PosId = response.List[0].posid;
            localStorage.setItem("Uname", loginsts.Uname);
            localStorage.setItem("Uid", loginsts.Uid);
            localStorage.setItem("Auth", loginsts.isAuth);
            localStorage.setItem("UserFlag", loginsts.UserFlag);
            localStorage.setItem("PosId", loginsts.PosId);
            this.$store.state.userId = loginsts.Uid;
            this.$store.state.userName = loginsts.Uname;
            this.$store.state.userFlag = loginsts.UserFlag;
            this.$store.state.posId = loginsts.PosId;
            this.$router.push("/dashboard");
          } else {
            this.err = true;
            this.errclass = "dangermsg";
            this.errmsg = "Invalid Username/Password!";
          }
        } else {
          this.err = true;
          this.errclass = "dangermsg";
          this.errmsg = "Unable to Login! Something Went Wrong!";
          console.log(response.ErrorMsg);
        }
      } else {
        this.err = true;
        this.errmsg = "Please Fill User Name/Password!";
        this.errclass = "dangermsg";
      }
    },
  },
};
</script>

<style>
@import "../css/main.css";
</style>

<style scoped>
.login-div {
  position: relative;
  margin: 0px auto !important;
  left: 0;
  right: 0;
  top: 50px;
  width: 350px;
  border-radius: 4px;
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: left;
  box-shadow: 0px 2px 4px 1px lightgray;
  background-color: white;
}

.inner-div {
  padding: 5px;
}

.logo {
  position: relative;
  display: block;
  margin: 0px auto !important;
  width: 80px;
}

label {
  position: relative;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 5px;
  display: block;
}

.btn {
  display: block;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.errmsg {
  position: relative;
  display: block;
  text-align: center;
  padding: 3px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}
.dangermsg {
  background-color: #f12b08f2;
  color: white;
}
.warnmsg {
  background-color: #ebca12;
  color: white;
}
.donemsg {
  background-color: #05bb23;
  color: white;
}

.devcontact {
  position: relative;
  display: block;
  margin-top: 5px;
  text-align: center;
}
.devcontact a {
  font-size: 15px;
  font-weight: bold;
  color: gray;
}
</style>
